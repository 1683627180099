import { useEffect, useState } from 'react'

const ScrollToElement = (id) => {
  const [scrollspyId, setScrollspyId] = useState()

  useEffect(() => {
    const elem = document.getElementById(id)
    setScrollspyId(elem)
  }, [])
  const scrollDown = () => {
    setTimeout(() => {
      scrollspyId?.scrollIntoView({
        behavior: 'smooth'
      })
    }, 12)
  }

  return scrollDown
}

export { ScrollToElement }
