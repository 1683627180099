const DataProducts = [
  {
    id: 1,
    cat: 1,
    code: 'Cổng nhôm đúc A1',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fcong1.jpg?alt=media&token=6fa91c43-3b74-491a-87f4-35c5b1055f58'
  },
  {
    id: 2,
    cat: 1,
    code: 'Cổng nhôm đúc A2',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fcong2.jpg?alt=media&token=b71e4d40-ab39-4b0a-a864-278dd4adbaf8'
  },
  {
    id: 3,
    cat: 1,
    code: 'Cổng nhôm đúc A3',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fcong3.jpg?alt=media&token=60ee3b4f-9a68-47ce-9c4d-d66a7a47dc42'
  },
  {
    id: 4,
    cat: 1,
    code: 'Cổng nhôm đúc A4',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fcong4.jpg?alt=media&token=a9e4e158-1c41-4574-959f-4b1991ae71bb'
  },
  {
    id: 44,
    cat: 1,
    code: 'Cổng nhôm đúc A5',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fcong5.jpg?alt=media&token=3a74be08-fa6c-4bb1-9e60-180d8ff4d918'
  },
  {
    id: 45,
    cat: 1,
    code: 'Cổng nhôm đúc A6',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fcong6.jpg?alt=media&token=a62b514f-2a41-4e4c-9d10-be46920aa041'
  },
  {
    id: 46,
    cat: 1,
    code: 'Cổng nhôm đúc A7',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fcong7.jpg?alt=media&token=4d889f5f-ce7f-4b23-9c94-30eb877a0085'
  },
  {
    id: 47,
    cat: 1,
    code: 'Cổng nhôm đúc A8',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fcong8.jpg?alt=media&token=d50531cc-65e9-40cb-908c-da50eae30f4b'
  },
  {
    id: 48,
    cat: 1,
    code: 'Cổng nhôm đúc A9',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fcong9.jpg?alt=media&token=e81751b4-d828-4291-94af-337fbc584b03'
  },
  {
    id: 49,
    cat: 1,
    code: 'Cổng nhôm đúc A10',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fcong10.jpg?alt=media&token=aaf06637-cc19-4d15-9b03-4915eb222ab4'
  },
  {
    id: 50,
    cat: 1,
    code: 'Cổng nhôm đúc A11',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fcong11.jpg?alt=media&token=25bb205f-3a1c-4554-9b6b-247777bc3f83'
  },
  {
    id: 51,
    cat: 1,
    code: 'Cổng nhôm đúc A12',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fcong12.jpg?alt=media&token=c1e5d33f-aa9a-43ac-9930-9934dc12b4fc'
  },
  {
    id: 5,
    cat: 2,
    code: 'Lan can nhôm đúc B1',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Flancan1.jpg?alt=media&token=bd46c489-8a30-4eca-afdc-dd10d40ea4af'
  },
  {
    id: 6,
    cat: 2,
    code: 'Lan can nhôm đúc B2',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Flancan2.jpg?alt=media&token=2ade3946-9a77-44a5-9d10-1b1d96de7dcc'
  },
  {
    id: 7,
    cat: 2,
    code: 'Lan can nhôm đúc B3',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Flancan3.jpg?alt=media&token=2d41dfe7-5d15-4814-b174-4785c924a6a6'
  },
  {
    id: 8,
    cat: 2,
    code: 'Lan can nhôm đúc B4',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Flancan4.jpg?alt=media&token=18ded4ec-66fc-4ac7-845c-e308825e5604'
  },
  {
    id: 88,
    cat: 2,
    code: 'Lan can nhôm đúc B5',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Flancan5.jpg?alt=media&token=dc0c8bd6-2730-4a69-b775-f57c334a78dc'
  },
  {
    id: 89,
    cat: 2,
    code: 'Lan can nhôm đúc B6',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Flancan6.jpg?alt=media&token=75282b4e-a631-4eb3-9759-0fe561d36356'
  },
  {
    id: 90,
    cat: 2,
    code: 'Lan can nhôm đúc B7',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Flancan7.jpg?alt=media&token=8f234324-996a-4f22-8c63-4259d0ce6220'
  },
  {
    id: 91,
    cat: 2,
    code: 'Lan can nhôm đúc B8',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Flancan8.jpg?alt=media&token=16d36727-1bc7-490c-9479-3c56a927ade7'
  },
  {
    id: 9,
    cat: 3,
    code: 'Cầu thang nhôm đúc C1',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fcauthang1.jpg?alt=media&token=6a8719e8-3857-49aa-88ac-b7b95384a0bf'
  },
  {
    id: 10,
    cat: 3,
    code: 'Cầu thang nhôm đúc C2',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fcauthang2.jpg?alt=media&token=e414f652-97e7-4ac8-a4aa-5e31b0977eec'
  },
  {
    id: 11,
    cat: 3,
    code: 'Cầu thang nhôm đúc C3',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fcauthang3.jpg?alt=media&token=9166bd7a-f9d0-40fb-9e70-3929f31b7f95'
  },
  {
    id: 12,
    cat: 3,
    code: 'Cầu thang nhôm đúc C4',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fcauthang4.jpg?alt=media&token=504a72db-ad90-4d27-b96a-6b5d938ac2c5'
  },
  {
    id: 122,
    cat: 3,
    code: 'Cầu thang nhôm đúc C5',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fcauthang5.jpg?alt=media&token=783800fb-7741-450c-bccd-6e1d2f5c36c5'
  },
  {
    id: 123,
    cat: 3,
    code: 'Cầu thang nhôm đúc C6',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fcauthang6.jpg?alt=media&token=e62f9691-322a-4c58-9bde-9f6c507a38b6'
  },
  {
    id: 124,
    cat: 3,
    code: 'Cầu thang nhôm đúc C7',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fcauthang7.jpg?alt=media&token=e79ff074-15ad-4a1a-b231-022b96de1124'
  },
  {
    id: 125,
    cat: 3,
    code: 'Cầu thang nhôm đúc C8',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fcauthang8.jpg?alt=media&token=83b8ddaa-f118-47a0-a9ff-93232bbce465'
  },
  {
    id: 13,
    cat: 4,
    code: 'Hàng rào nhôm đúc D1',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fhangrao1.jpg?alt=media&token=daf1a313-e843-4408-b678-515e4848b652'
  },
  {
    id: 14,
    cat: 4,
    code: 'Hàng rào nhôm đúc D2',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fhangrao2.jpg?alt=media&token=b8c1b165-4378-41ba-80a7-4af9acf70adc'
  },
  {
    id: 15,
    cat: 4,
    code: 'Hàng rào nhôm đúc D3',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fhangrao3.jpg?alt=media&token=9e3ca176-0113-4fde-8e5d-d197586f85dc'
  },
  {
    id: 16,
    cat: 4,
    code: 'Hàng rào nhôm đúc D4',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fhangrao4.jpg?alt=media&token=295759fb-2f05-41d7-b52e-15b1d15d8825'
  },
  {
    id: 166,
    cat: 4,
    code: 'Hàng rào nhôm đúc D5',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fhangrao5.jpg?alt=media&token=1ee28907-1316-4bef-b4cc-223821499e4c'
  },
  {
    id: 167,
    cat: 4,
    code: 'Hàng rào nhôm đúc D6',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fhangrao6.jpg?alt=media&token=d0230123-fc18-4db9-8e9a-3551534d19ba'
  },
  {
    id: 168,
    cat: 4,
    code: 'Hàng rào nhôm đúc D7',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fhangrao7.jpg?alt=media&token=87c0f2c3-0b4a-4e7c-bf2a-1bd2def31b90'
  },
  {
    id: 169,
    cat: 4,
    code: 'Hàng rào nhôm đúc D8',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fhangrao8.webp?alt=media&token=6ba2cf2d-2075-4cc2-a0ea-fc87d81bc1c3'
  }
]

export default DataProducts
