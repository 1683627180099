import React from 'react'

//CostumHook kiểm tra kích thước màn hình để hiển thị cho đúng reponsive
const UseViewport = (pixel) => {
  const [width, setWidth] = React.useState(window.innerWidth)

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return width <= pixel
}

export { UseViewport }
