import React from 'react'
import './style.scss'
import DataPhone from '../../../../data/phone'

const Slide = ({ moveContact }) => {
  return (
    <div className='slide_div'>
      <img src={process.env.PUBLIC_URL + '/images/slide.png'} />
      {moveContact != undefined && (
        <div className={moveContact ? 'move_contact' : 'move_contact hidden'}>
          <a href='tel:0964040895'>
            <i className='fas fa-phone-volume'></i> <br />
            <img src={process.env.PUBLIC_URL + '/images/zalo_icon.png'} />
          </a>
          <div>
            <p>GỌI NGAY</p>
            <p>{DataPhone.phone_dot}</p>
          </div>
        </div>
      )}

      <div id='scrollspy_category'></div>
    </div>
  )
}

export default Slide
