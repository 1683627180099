import React from 'react'
import DataProcedure from '../../../../data/procedure'
import './style.scss'

const Procedure = () => {
  return (
    <>
      <h1>Quy trình</h1>
      <div className='procedure_box'>
        {DataProcedure.map((procedure) => (
          <div className='procedure_item' key={procedure.id}>
            <div className='procedure_square'>
              <h5>{procedure.name}</h5>
              <img src={procedure.img} alt='quy-trinh-thi-cong-lap-dat-nhom-duc-bmt' />
            </div>
            <div className='procedure_circle'>
              <h3>{procedure.stt}</h3>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default Procedure
