import React from 'react'
import CategoryList from './components/categoryList'
import Procedure from './components/procedure'
import Introduce from './components/introduce'
import Post from './components/post'
import Footer from '../footer'
import Header from '../header'
import { Route, Routes } from 'react-router-dom'
import ProductList from './components/productList'
import NhomDucBMT from './components/nhom-duc-bmt'
import { BackTop, FloatButton } from 'antd'
import Policy from './components/policy'
import Notfound from '../notfound'
import Formmail from './components/formmail'

const Content = () => {
  return (
    <div>
      <Header />
      <Routes>
        <Route path='san-pham/:url_category' element={<ProductList />} />
        <Route path='bang-gia/:url_category' element={<ProductList />} />
        <Route path='chinh-sach/:policy' element={<Policy />} />
        <Route path='cong-cua/:diachi' element={<NhomDucBMT />} />
        <Route index element={<CategoryList />} />
        <Route path='*' element={<Notfound />} />
      </Routes>

      <Introduce />
      <Procedure />
      <Post />
      <Formmail />
      <Footer />

      <div>
        <FloatButton.BackTop />
      </div>
    </div>
  )
}

export default Content
