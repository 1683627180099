import React from 'react'
import { EnvironmentOutlined, PhoneOutlined, MailOutlined, FacebookOutlined } from '@ant-design/icons'
import { FacebookProvider, Page, Comments } from 'react-facebook'
import DataCategories from '../../data/categories'
import './style.scss'
import { Link } from 'react-router-dom'

import { UseViewport } from '../../util/screen'
import { ScrollToElement } from '../../util/scrollToElement'
import DataPolicy from '../../data/policy'
import DataPhone from '../../data/phone'

const Footer = () => {
  const scrollDownCategory = ScrollToElement('scrollspy_category')
  const isMobile = UseViewport(760)

  return (
    <>
      <div id='scrollspy_footer' style={{ marginBottom: '50px' }}></div>
      <div className='footer_box'>
        <div className='footer_contact'>
          <h2>Thông tin liên hệ</h2>
          <div>
            {/* <img src='https://congducthanhnam.com/wp-content/uploads/2023/03/logo_tn2-1.png' /> */}
            <img src={process.env.PUBLIC_URL + '/images/main.png'} alt='nhom-duc-tot-nhat-bmt-dak-lak' />
            <p>
              <EnvironmentOutlined /> 277 Lê Duẩn, Buôn Ma Thuột, Đắk Lắk
            </p>
            <p>
              <PhoneOutlined /> Hotline: {DataPhone.phone_dot}
            </p>
            <p>
              <MailOutlined /> nhomducbmt@gmail.com
            </p>
            <p>
              <FacebookOutlined />
              &nbsp;
              <a href='https://fb.com/nhomducbmt' target='_blank' style={{ textDecoration: 'none' }}>
                fb.com/nhomducbmt
              </a>
            </p>
          </div>
        </div>
        <div className='footer_product'>
          <h2>Sản phẩm</h2>
          <div className='footer_product_Item_box'>
            {DataCategories.map((category) => (
              <p key={category.id}>
                <Link
                  to={category.url}
                  className='footer_product_item'
                  onClick={!isMobile ? scrollDownCategory : () => {}}
                >
                  {category.name}
                </Link>
              </p>
            ))}
          </div>
        </div>
        <div className='footer_policy'>
          <h2>Chính sách</h2>
          <div>
            {/* <p>Chính sách bảo hành, bảo trì</p>
            <p>Chính sách thi công, lắp đặt</p>
            <p>Chính sách bảo mật</p>
            <p>Điều khoản sử dụng</p> */}

            {DataPolicy.map((policy) => (
              <p key={policy.id}>
                <Link to={`chinh-sach/${policy.path} `}>{policy.name}</Link>
              </p>
            ))}
          </div>
        </div>
        <div className='footer_connect'>
          <h2>Kết nối với chúng tôi</h2>
          <div className='footer_connect_social'>
            <a href='https://www.facebook.com/nhomducbmt' target='_blank'>
              <img src={process.env.PUBLIC_URL + '/images/social/fb.png'} />
            </a>
            <a href='https://www.youtube.com/watch?v=XcRBs4MXRF8' target='_blank'>
              <img src={process.env.PUBLIC_URL + '/images/social/yt.png'} />
            </a>
            {/* <a href='https://chat.zalo.me/?phone=0782722378' target='_blank'>
              <img src={process.env.PUBLIC_URL + '/images/social/zl.png'} />
            </a> */}
          </div>
          <div>
            <FacebookProvider appId='2238221736366394'>
              <Page href='https://www.facebook.com/nhomducbmt/' tabs='timeline' height={70} />
            </FacebookProvider>
          </div>
        </div>
      </div>
      <div className='bac'>© 2013 - {new Date().getFullYear()} design by B.A.C</div>
    </>
  )
}

export default Footer
