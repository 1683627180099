import logo from './logo.svg'
import './App.css'
import Content from './page/content'
import { Route, Routes } from 'react-router-dom'
import ContextProvider from './util/context'

function App() {
  ////để đổi màu thanh cuộn
  // useEffect(() => {
  //   function changeScrollbarColor() {
  //     var style = document.createElement('style')
  //     style.innerHTML = `
  //       ::-webkit-scrollbar-thumb {
  //         background-color: #163244; /* Màu mới của thanh cuộn */
  //       }
  //     `
  //     document.head.appendChild(style)
  //   }

  //   // Gọi hàm để thay đổi màu thanh cuộn khi trang được tải
  //   changeScrollbarColor()
  // }, [])

  return (
    <ContextProvider>
      <div className='App'>
        <Routes>
          <Route path='/*' element={<Content />} />
        </Routes>
      </div>
    </ContextProvider>
  )
}

export default App
