const DataProcedure = [
  {
    id: 1,
    stt: 1,
    name: 'Sản xuất tại xưởng',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fqt2.png?alt=media&token=d0d3c1a6-f3fe-452c-bda0-55e27d07d3aa'
  },
  {
    id: 2,
    stt: 2,
    name: 'Lắp đặt thi công',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fqt2.png?alt=media&token=d0d3c1a6-f3fe-452c-bda0-55e27d07d3aa'
  },
  {
    id: 3,
    stt: 3,
    name: 'Tân trang cổng cửa',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fqt3.png?alt=media&token=a8591842-c6c0-4133-b1f4-ea53423ce2a4'
  },
  {
    id: 4,
    stt: 4,
    name: 'Khách hàng đánh giá',
    img: 'https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/nhomduc%2Fqt4.png?alt=media&token=ccaf9391-13e6-4f84-949e-10a9a9d51a9a'
  }
]

export default DataProcedure
