const DataVideo = [
  {
    id: 1,
    name: 'Thi công lắp đặt Cổng nhôm đúc tại BMT',
    code: 'XcRBs4MXRF8'
  },
  {
    id: 2,
    name: 'Chi tiết hoa văn Cổng Nhôm Đúc',
    code: 'xGXq_dru0Vw'
  },
  {
    id: 3,
    name: 'Cổng nhôm đúc đẹp Buôn Ma Thuột, Đắk Lắk',
    code: 'N7QZ_QU1dDw'
  }
]

export default DataVideo
