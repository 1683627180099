const baiviet = [
  {
    id: 1,
    tinh: 'Đắk Lắk',
    diachi1: 'BMT, Đắk Lắk',
    diachi2: 'thành phố Buôn Ma Thuột, Đắk Lắk',
    huyen:
      'thành phố Buôn Ma Thuột và 12 huyện: Buôn Đôn, Cư M’gar, Ea H’leo, Ea Kar, Ea Súp, Krông Ana, Krông Bông, Krông Búk, Krông Năng, Krông Pắk, Lắk, M’Đrắk và thị xã Buôn Hồ',
    alt1: 'cong-cua-nhom-duc-tai-bmt-dak-lak',
    alt2: 'cong-cua-nhom-duc-re-nhat-bmt-dak-lak',
    alt3: 'cong-cua-nhom-duc-tot-nhat-bmt-dak-lak',
    img: 'https://viettinhhoa.com.vn/public/userfiles/images/San%20pham/Cong/z3273019874242_584fdb3afa831cce19349e7dfe716e5f.jpg',
    url: 'cong-cua/nhom-duc-bmt-dak-lak'
  },
  {
    id: 2,
    tinh: 'Gia Lai',
    diachi1: 'Pleiku, Gia Lai',
    diachi2: 'thành phố Pleiku, Gia Lai',
    huyen:
      'thành phố Pleiku, Thị xã An Khê, Thị xã Ayun Pa và 14 huyện: Chư Păh, Chư Prông, Chư Pưh, Chư Sê, Đắk Đoa, Đak Pơ, Đức Cơ, Ia Grai, Ia Pa, K’Bang, Kông Chro, Krông Pa, Mang Yang và huyện Phú Thiện',
    alt1: 'cong-cua-nhom-duc-tai-pleiku-gia-lai',
    alt2: 'cong-cua-nhom-duc-re-nhat-pleiku-gia-lai',
    alt3: 'cong-cua-nhom-duc-tot-nhat-pleiku-gia-lai',
    img: 'https://cuacongcaocap.vn/public/userfiles/images/vi-sao-cong-nhom-duc-duoc-ua-chuong-su-dung-cho-biet-thu-1.jpg',
    url: 'cong-cua/nhom-duc-pleiku-gia-lai'
  },
  {
    id: 3,
    tinh: 'Đắk Nông',
    diachi1: 'Gia Nghĩa, Đắk Nông',
    diachi2: 'thị xã Gia Nghĩa, Đắk Nông',
    huyen: 'Thị xã Gia Nghĩa và 7 huyện: Cư Jút, Đắk Mil, Krông Nô, Đắk Song, Đắk R Lấp, Đắk Glong và Tuy Đức',
    alt1: 'cong-cua-nhom-duc-tai-gia-nghia-dak-nong',
    alt2: 'cong-cua-nhom-duc-re-nhat-gia-nghia-dak-nong',
    alt3: 'cong-cua-nhom-duc-tot-nhat-gia-nghia-dak-nong',
    img: 'https://nhomducphugiahung.com/images/uploads/news/cong_nhom_duc_1799611698_2133849076.jpeg',
    url: 'cong-cua/nhom-duc-gia-nghia-dak-nong'
  }
]
export default baiviet
