import React, { useEffect, useState } from 'react'
import './style.scss'
import baiviet from '../../../../data/baiviet'
import { useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import DataPhone from '../../../../data/phone'

const NhomDucBMT = () => {
  const today = new Date()
  const date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
  const [where, setWhere] = useState()

  const { diachi } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const value = (chuoi) => diachi.includes(chuoi)
    if (value('bmt-dak-lak')) {
      setWhere(baiviet[0])
    } else if (value('pleiku-gia-lai')) {
      setWhere(baiviet[1])
    } else if (value('gia-nghia-dak-nong')) {
      setWhere(baiviet[2])
    } else navigate(-1)
  }, [diachi])

  return (
    <>
      <Helmet>
        {/* Standard metadata tags */}
        <title>{`Làm Cổng Nhôm Đúc tại ${where?.tinh} - CHẤT LƯỢNG & RẺ NHẤT 100%`}</title>
        <meta
          name='description'
          content={`Lắp đặt Cổng Cửa, Cầu Thang, Lan Can, Hàng Rào,.. Nhôm Đúc tại ${where?.diachi1} cam kết Chất & Rẻ Nhất. Nhận thi công Toàn Quốc. Hơn 1000+ khách hàng`}
        />
        {/* End standard metadata tags */}

        {/* Facebook tags */}
        <meta property='og:type' content='website' />
        <meta property='og:title' content={`Cổng cửa Nhôm Đúc tại ${where?.diachi1} - Chất & Rẻ 100%`} />
        <meta
          property='og:image'
          content='https://firebasestorage.googleapis.com/v0/b/fir-redux-toolkit-query.appspot.com/o/images%2FThi%E1%BA%BFt%20k%E1%BA%BF%20ch%C6%B0a%20c%C3%B3%20t%C3%AAn.png?alt=media&token=05faab7b-4066-4193-b33d-d66856113922'
        />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta
          property='og:description'
          content={`Thi công lắp đặt Cổng Cửa Nhôm Đúc Chất & Rẻ nhất ${where?.diachi1}`}
        />
        {/* End Facebook tags */}
      </Helmet>

      <div className='nhom_duc_bmt'>
        <div className='nhom_duc_bmt_title'>
          <h3>Dịch vụ thi công lắp đặt</h3>
          <h1>Cổng cửa, lan can, cầu thang, hàng rào... Nhôm đúc Chất & Rẻ Nhất tại {where?.diachi1}</h1>
          <p>POSTED ON: {date} BY NHOM DUC TUAN MINH BMT</p>
        </div>
        <div className='nhom_duc_bmt_content'>
          <h3>Nhôm đúc Tuân Minh hân hạnh được phục vụ quý khách hàng tại địa bàn {where?.diachi2}</h3>
          <br />
          <p>
            Công ty Nhôm đúc TUÂN MINH có nhiều năm thi công lắp đặt tại địa bàn tỉnh {where?.tinh} trong đó gồm{' '}
            {where?.huyen}. Các sản phẩm bằng nhôm đúc đã đem lại vẻ đẹp tinh tế cho ngôi nhà của quý khách hàng.
          </p>
          <div className='img_box'>
            <img src={process.env.PUBLIC_URL + '/images/daklak/img1.jpg'} alt={where?.alt1} title={where?.alt1} />
          </div>
          <p>
            Các sản phẩm của NHÔM ĐÚC TUÂN MINH như: Cửa cổng, hàng rào, lan can, cầu thang, bàn ghế, hoa văn trang trí
            nội ngoại thất.… bằng vật liệu Hợp kim nhôm cao cấp với màu sắc và họa tiết họa văn tỉ mỉ đến từng chi tiết
            sẽ mang đến sự khác biệt "Đẳng cấp Hoàng Gia" cho ngôi nhà của bạn mà bất cứ ai nhìn từ bên ngoài và từ xa
            đều phải mơ ước.
          </p>
          <div className='doan_noi_bat'>
            <i>
              Bạn đang xây dựng ngôi nhà mơ ước đang tìm một công ty thi công lắp đặt Cổng cửa, Lan can, Cầu thang, Hàng
              rào,... Nhôm đúc chất lượng & uy tín tại {where?.diachi1} - Nhôm Đúc TUÂN MINH sẽ đáp ứng mọi chỉ tiêu của
              quý khách hàng với chất lượng uy tín tận tâm. <b>Cam kết CHẤT Lượng & RẺ Nhất {where?.diachi1}</b>.
            </i>
          </div>
          <div className='img_box'>
            <img src={process.env.PUBLIC_URL + '/images/daklak/img2.jpg'} alt={where?.alt2} title={where?.alt2} />
          </div>
          <div className='img_box'>
            <img src={process.env.PUBLIC_URL + '/images/daklak/img3.jpg'} alt={where?.alt3} title={where?.alt3} />
          </div>
          <p>
            Với đội ngũ các Kiến trúc sư, kỹ sư được đào tạo bài bản qua các trường lớp danh tiếng trong lĩnh vực xây
            dựng, mỹ thuật, kiến trúc giỏi chuyên môn, giàu kinh nghiệm và tập hợp đội ngũ các công nhân kỹ thuật tay
            nghề cao tại các làng nghề đồng, nhôm nổi tiếng trên khắp mọi miền đất nước; kết hợp với nhiều thiết bị máy
            móc hiện đại, ứng dụng được tất cả các công nghệ khoa học và kỹ thuật cao và tiến tiến nhất hiện nay như:
            Công nghệ đúc chân không, sơn phủ na nô, sơn tĩnh điện… vào quy trình sản xuất; từ khâu thiết kế đến việc
            lắp đặt sản phầm đều được quản lý và kiểm tra chặt chẽ sẽ cho ra những sản phẩm Hợp Kim Nhôm cao cấp đáp ứng
            được nhu cầu của khách hàng dù là khắt khe nhất. Các sản phẩm của NHÔM ĐÚC TUÂN MINH đáp ứng được các yêu
            cầu về mỹ thuật và kỹ thuật như: Sang trọng, nhẹ nhàng, sắc sảo, chắc chắn, không bị rỉ sét, chống chịu được
            các điều kiện thời tiết khắc nghiệt, không độc hại và thân thiện với môi trường. <br />
            Nhà xưởng Công ty đặt tại khu vực Đắk Lắk để phục vụ các tỉnh Tây Nguyên, Nam Trung Bộ và thi công toàn
            quốc. Với đại lý phân phối và các văn phòng đại diện tại nhiều nơi trên cả nước. <br />
            Vì vậy để tìm kiếm và sử dụng dịch vụ thi công lắp đặt Cổng cửa, Lan can, Cầu thang, Hàng rào,... Nhôm Đúc
            uy tín, Chất lượng và RẺ nhất {where?.diachi1}. Quý khách hàng chỉ cần zalo hoặc call: {DataPhone.phone_dot}{' '}
            (NHÔM ĐÚC TUÂN MINH hân hạnh phục vụ)
          </p>
        </div>
      </div>
    </>
  )
}

export default NhomDucBMT
