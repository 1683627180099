import React from 'react'
import './style.scss'
import DataPhone from '../../../../data/phone'

const Contact = () => {
  return (
    <div className='contact_box'>
      <a href={`tel:${DataPhone.phone}`}>
        <i className='fas fa-phone-volume'></i>
      </a>
      <p>Nhấn gọi (zalo)</p>
    </div>
  )
}

export default Contact
