import React, { createContext, useState } from 'react'
// Initiate Context
export const Context = createContext()
// Provide Context
export const ContextProvider = ({ children }) => {
  const [element, setElement] = useState('')

  return <Context.Provider value={{ element, setElement }}>{children}</Context.Provider>
}

export default ContextProvider
