const DataCategories = [
  {
    id: 1,
    name: 'Cổng nhôm đúc',
    name_path: 'cong-nhom-duc-bmt',
    url: 'san-pham/cong-nhom-duc-bmt',
    url_price: 'bang-gia/cong-nhom-duc-bmt',
    name_lowercase: 'cổng nhôm đúc',
    price: '7.500.000 – 9.500.000 VNĐ/m2',
    dependent: [
      'Hoa văn trên cổng đơn giản hay phức tạp và tinh tế.',
      'Độ dày của cửa cổng nhôm đúc.',
      'Kích thước cổng lớn hay nhỏ.',
      'Độ tinh khiết của nhôm làm nguyên liệu chính.',
      'Giá đã bao gồm chi phí lắp đặt và vận chuyển tận nơi chưa?',
      'Màu sơn sử dụng có phải là loại cao cấp không, hạn sử dụng là bao lâu?',
      'Giá đã bao gồm VAT chưa?'
    ],
    description: [
      'Cổng nhôm đúc là một giải pháp tuyệt vời để tạo nên một lối vào sang trọng và bảo vệ an ninh cho không gian sống của bạn. Với sự kết hợp giữa tính nhẹ, độ bền và tính thẩm mỹ, cổng nhôm đúc mang đến một cái nhìn đẹp mắt và độc đáo cho ngôi nhà hoặc khuôn viên của bạn.',
      'Đầu tiên, hãy nói về vật liệu chính của cổng nhôm đúc. Nhôm là một vật liệu nhẹ, nhưng cực kỳ bền và chống ăn mòn. Điều này cho phép cổng nhôm đúc chịu được sự tác động của thời tiết và môi trường mà không bị mục nát hay suy giảm độ bền. Tính nhẹ của nhôm cũng giúp việc cài đặt cổng trở nên dễ dàng và tiết kiệm thời gian.',
      'Tiếp theo, hãy nói về thiết kế và kiểu dáng của cổng nhôm đúc. Cổng nhôm đúc có sự đa dạng về kiểu dáng và hoa văn, từ những mẫu đơn giản và hiện đại đến những mẫu phức tạp và cổ điển. Bạn có thể tùy chỉnh cổng nhôm đúc theo sở thích và phong cách kiến trúc của ngôi nhà. Từ các đường nét sắc sảo đến những hoa văn tinh tế, cổng nhôm đúc mang đến sự sang trọng và đẳng cấp cho ngôi nhà của bạn.',
      'Không chỉ là một yếu tố thẩm mỹ, cổng nhôm đúc còn đảm bảo tính an ninh và bảo vệ cho không gian sống. Với độ cứng và sức mạnh của nhôm, cổng nhôm đúc tạo ra một rào cản chắc chắn và bền vững. Bạn có thể tùy chọn các yếu tố bảo vệ bổ sung như hệ thống khóa an toàn, cửa tự động và các tùy chọn an ninh khác để đảm bảo an toàn cho gia đình và tài sản của bạn.',
      'Cuối cùng, việc bảo trì và vệ sinh cổng nhôm đúc cũng rất đơn giản. Nhôm không yêu cầu nhiều công việc bảo dưỡng và có khả năng chống ăn mòn, giúp cổng duy trì vẻ đẹp và chất lượng trong thời gian dài.',
      'Với tính nhẹ, độ bền và tính thẩm mỹ, cổng nhôm đúc là lựa chọn lý tưởng để nâng cao giá trị và tạo điểm nhấn cho ngôi nhà của bạn. Hãy khám phá sự tinh tế và chất lượng của cổng nhôm đúc và biến không gian sống của bạn thành một nơi an lành, đẹp mắt và an toàn!'
    ]
  },
  {
    id: 2,
    name: 'Lan can nhôm đúc',
    name_path: 'lan-can-nhom-duc-bmt',
    url: 'san-pham/lan-can-nhom-duc-bmt',
    url_price: 'bang-gia/lan-can-nhom-duc-bmt',
    name_lowercase: 'lan can nhôm đúc',
    price: '3.000.000 – 5.000.000 VNĐ/m2',
    dependent: [
      'Họa tiết hoa văn.',
      'Độ dày mỏng, kích thước.',
      'Giá lan can nhôm đúc sẽ càng thấp nếu sản phẩm được đúc theo khuôn mẫu sẵn có. Vì đúc theo khuôn mẫu giúp giảm được rất nhiều chi phí trong quá trình sản xuất.',
      'Các mẫu theo yêu cầu có hoa văn hay phù điêu phức tạp thì giá lan can nhôm đúc mạ đồng sẽ cao hơn. Bởi sản phẩm có hoa văn được điêu khắc tỉ mỉ sẽ tốn rất nhiều thời gian và nhân công lao động.'
    ],
    description: [
      'Lan can nhôm đúc là một giải pháp tuyệt vời để tăng tính thẩm mỹ, độ bền và an toàn cho không gian sống của bạn. Với sự kết hợp giữa sự thanh lịch của nhôm và độ cứng của quá trình đúc, lan can nhôm đúc không chỉ mang đến một cái nhìn đẹp mắt mà còn cung cấp một hàng rào chắc chắn và bảo vệ an toàn cho gia đình và khách hàng của bạn.',
      'Đầu tiên, hãy nói về vật liệu chính của lan can nhôm đúc. Nhôm là một vật liệu rất phổ biến trong ngành xây dựng vì tính nhẹ, chống ăn mòn và bền bỉ. Lan can nhôm đúc không chỉ chống lại các tác động của thời tiết và thời gian, mà còn không bị mục nát, cong vênh hoặc bị tác động bởi côn trùng và mối mọt. Điều này giúp giảm thiểu nhu cầu bảo trì và sửa chữa, giúp lan can nhôm đúc trở thành một lựa chọn lý tưởng cho việc xây dựng các công trình lâu dài.',
      'Tiếp theo, hãy nói về thiết kế và kiểu dáng của lan can nhôm đúc. Lan can nhôm đúc có sự đa dạng về kiểu dáng và hoa văn, từ những mẫu đơn giản và hiện đại cho đến những mẫu phức tạp và cổ điển. Bạn có thể tùy chỉnh lan can nhôm đúc theo sở thích cá nhân và phong cách kiến trúc của không gian sống. Từ các đường nét tinh tế đến những hoa văn phong phú, lan can nhôm đúc mang đến sự sang trọng và độc đáo cho ngôi nhà của bạn.',
      'Không chỉ có tính thẩm mỹ, lan can nhôm đúc còn đảm bảo tính an toàn và bảo vệ cho người sử dụng. Với độ cứng và sức mạnh của nhôm, lan can nhôm đúc tạo ra một hàng rào vững chắc để ngăn chặn sự rơi từ cao, bảo vệ trẻ em và người lớn khỏi nguy hiểm. Bạn cũng có thể tùy chọn các yếu tố bảo vệ bổ sung như mạng lưới an toàn hoặc vật liệu kính cường lực để tăng độ an toàn cho lan can.',
      'Cuối cùng, việc lắp đặt lan can nhôm đúc cũng rất dễ dàng và tiện lợi. Nhôm có trọng lượng nhẹ hơn so với các vật liệu khác như thép, giúp việc vận chuyển và cài đặt trở nên dễ dàng hơn. Hơn nữa, lan can nhôm đúc không yêu cầu bảo trì đặc biệt và có tuổi thọ cao, giúp bạn tiết kiệm thời gian và tiền bạc trong quá trình sử dụng.',
      'Với sự kết hợp giữa tính thẩm mỹ, độ bền và tính an toàn, lan can nhôm đúc là lựa chọn lý tưởng để nâng cao giá trị và tạo điểm nhấn cho không gian sống của bạn. Hãy trải nghiệm sự tinh tế và chất lượng của lan can nhôm đúc để tạo ra một không gian sống đẹp và an lành!'
    ]
  },
  {
    id: 3,
    name: 'Cầu thang nhôm đúc',
    name_path: 'cau-thang-nhom-duc-bmt',
    url: 'san-pham/cau-thang-nhom-duc-bmt',
    url_price: 'bang-gia/cau-thang-nhom-duc-bmt',
    name_lowercase: 'cầu thang nhôm đúc',
    price: '3.500.000 – 4.500.000 VNĐ/m2',
    dependent: [
      'Báo giá lan can cầu thang nhôm đúc được tính theo mét chiều dài và chiều cao tùy thuộc vào kích thước của mỗi công trình.',
      'Đơn giá cầu thang nhôm đúc rẻ hay đắt còn phụ thuộc theo mẫu mã và khối lượng. Yêu cầu càng phức tạp, đòi hỏi công sức của thợ và thời gian thi công càng nhiều theo đó giá cũng sẽ càng cao.',
      'Khối lượng cầu thang càng nhiều sẽ giúp giảm đơn giá của sản phẩm vì chi phí khuôn mẫu được giảm trừ.',
      'Thời gian khách hàng yêu cầu cũng ảnh hưởng nhiều đến giá thành sản phẩm. Thời gian càng gấp rút thì nhân công phải tăng ca để hoàn thành đúng hẹn để giao khách.'
    ],
    description: [
      'Cầu thang nhôm đúc đã trở thành một trong những giải pháp hàng đầu cho việc xây dựng cầu thang hiện đại. Với sự kết hợp giữa tính nhẹ, độ bền và tính thẩm mỹ, cầu thang nhôm đúc mang đến một cái nhìn đẹp mắt và độc đáo cho không gian nội thất và ngoại thất.',
      'Đầu tiên, hãy nói về vật liệu chính của cầu thang nhôm đúc. Nhôm là một vật liệu nhẹ, nhưng cực kỳ bền và chống ăn mòn. Điều này cho phép cầu thang nhôm đúc chịu được tải trọng lớn mà không bị mục nát hay suy giảm độ bền. Với tính nhẹ của nhôm, việc xây dựng và lắp đặt cầu thang nhôm đúc trở nên dễ dàng và tiết kiệm thời gian.',
      'Tiếp theo, hãy nói về thiết kế và kiểu dáng của cầu thang nhôm đúc. Cầu thang nhôm đúc có sự đa dạng về kiểu dáng và hoa văn, từ các mẫu đơn giản và hiện đại đến các mẫu phức tạp và cổ điển. Bạn có thể tùy chỉnh cầu thang nhôm đúc theo sở thích và phong cách kiến trúc của không gian nội thất. Từ các đường nét sắc sảo đến những hoa văn tinh tế, cầu thang nhôm đúc mang đến sự sang trọng và đẳng cấp cho không gian sống của bạn.',
      'Không chỉ là một phần của thiết kế nội thất, cầu thang nhôm đúc còn đảm bảo tính an toàn và ổn định cho người sử dụng. Với độ cứng và sức mạnh của nhôm, cầu thang nhôm đúc tạo ra một bước chân chắc chắn và ổn định. Bạn cũng có thể tùy chọn các yếu tố bảo vệ bổ sung như lan can, tay vịn và bề mặt chống trượt để tăng độ an toàn cho cầu thang.',
      'Cuối cùng, việc bảo trì và vệ sinh cầu thang nhôm đúc cũng rất đơn giản. Nhôm không yêu cầu quá nhiều công việc bảo dưỡng và có khả năng chống ăn mòn, giúp cầu thang nhôm đúc duy trì vẻ đẹp và chất lượng trong thời gian dài.',
      'Với tính nhẹ, độ bền và tính thẩm mỹ, cầu thang nhôm đúc là sự lựa chọn tuyệt vời để nâng cao giá trị và tạo điểm nhấn cho không gian sống và làm việc của bạn. Hãy khám phá sự tinh tế và chất lượng của cầu thang nhôm đúc và biến không gian của bạn thành một tác phẩm nghệ thuật đẳng cấp!'
    ]
  },
  {
    id: 4,
    name: 'Hàng rào nhôm đúc',
    name_path: 'hang-rao-nhom-duc-bmt',
    url: 'san-pham/hang-rao-nhom-duc-bmt',
    url_price: 'bang-gia/hang-rao-nhom-duc-bmt',
    name_lowercase: 'hàng rào nhôm đúc',
    price: '4.000.000 – 6.000.000 VNĐ/m2',
    dependent: [
      'Mẫu mã hàng rào nhôm đúc: Nếu hàng rào nhôm đúc đơn giản không cầu kỳ hay hoa văn phức tạp thì giá thường sẽ rẻ hơn. Vì các mẫu có hoa văn hay phù điều thì cần tốn thêm nguyên liệu nhôm đúc cũng như thời gian sản xuất.',
      'Khối lượng: Nếu khách hàng đặt mua hàng rào nhôm đúc theo 1 khuôn mẫu duy nhất với số lượng nhiều thì chi phí sẽ thấp hơn việc dùng nhiều loại hàng rào khác nhau.',
      'Thời gian thi công: Quy trình sản xuất và hoàn thiện hàng rào hợp kim nhôm thường rơi vào từ 10 – 30 ngày. Nếu khách hàng vì lí do công trình cần hoàn thiện sớm, chúng tôi sẽ cho thợ tăng ca để cho ra thành phẩm sớm nhất.'
    ],
    description: [
      'Hàng rào nhôm đúc là một giải pháp tuyệt vời cho việc xây dựng hệ thống rào cản và bảo vệ cho không gian ngoại thất và nội thất. Với sự kết hợp giữa tính nhẹ, độ bền và tính thẩm mỹ, hàng rào nhôm đúc mang đến một vẻ đẹp hiện đại và độc đáo cho môi trường sống của bạn.',
      'Đầu tiên, hãy nói về vật liệu chính của hàng rào nhôm đúc. Nhôm là một vật liệu nhẹ, nhưng cực kỳ bền và chống ăn mòn. Điều này cho phép hàng rào nhôm đúc chịu được các yếu tố thời tiết khắc nghiệt như mưa, nắng, gió và muối biển mà không bị mục nát hay suy giảm độ bền. Tính nhẹ của nhôm cũng giúp việc lắp đặt hàng rào trở nên dễ dàng và tiết kiệm thời gian.',
      'Tiếp theo, hãy nói về thiết kế và kiểu dáng của hàng rào nhôm đúc. Hàng rào nhôm đúc có sự đa dạng về kiểu dáng và hoa văn, từ những mẫu đơn giản và hiện đại đến những mẫu phức tạp và cổ điển. Bạn có thể tùy chỉnh hàng rào nhôm đúc theo sở thích và phong cách kiến trúc của không gian sống. Từ các đường nét sắc sảo đến những hoa văn tinh tế, hàng rào nhôm đúc mang đến sự sang trọng và đẳng cấp cho không gian của bạn.',
      'Không chỉ là một yếu tố thẩm mỹ, hàng rào nhôm đúc còn đảm bảo tính an toàn và bảo vệ cho môi trường sống. Với độ cứng và sức mạnh của nhôm, hàng rào nhôm đúc tạo ra một rào cản chắc chắn và bền vững. Bạn có thể tùy chọn các yếu tố bảo vệ bổ sung như cổng an toàn, tay vịn và các chấn động chống trượt để tăng độ an toàn cho không gian.',
      'Cuối cùng, việc bảo trì và vệ sinh hàng rào nhôm đúc cũng rất đơn giản. Nhôm không yêu cầu nhiều công việc bảo dưỡng và có khả năng chống ăn mòn, giúp hàng rào duy trì vẻ đẹp và chất lượng trong thời gian dài.',
      'Với tính nhẹ, độ bền và tính thẩm mỹ, hàng rào nhôm đúc là lựa chọn tuyệt vời để tăng cường an ninh và tạo điểm nhấn cho không gian sống của bạn. Hãy khám phá sự tinh tế và chất lượng của hàng rào nhôm đúc và biến không gian của bạn thành một nơi an lành và đẹp mắt!'
    ]
  }
]

export default DataCategories
