import React from 'react'
import './style.scss'

const Introduce = () => {
  return (
    <>
      <div id='scrollspy_introduce' style={{ marginBottom: '66px' }}></div>

      <div className='introduce_box'>
        <div className='introduce'>
          <h2>Giới thiệu</h2>
          <h1>Nhôm đúc Tuân Minh BMT</h1>
          <p className='introduce_content'>
            Chào mừng đến với công ty nhôm đúc Tuân Minh! Tuân Minh tận hưởng việc tạo ra những sản phẩm nhôm đúc chất
            lượng cao và đáng tin cậy. Với nhiều năm kinh nghiệm và kiến thức sâu sắc trong ngành công nghiệp, nhôm đúc
            Tuân Minh tự hào là đơn vị hàng đầu trong lĩnh vực nhôm đúc, sử dụng công nghệ tiên tiến và quy trình sản
            xuất hiện đại nhất để tạo ra những sản phẩm nhôm đúc chất lượng cao, từ các chi tiết nhỏ cho đến các cấu
            trúc lớn. Đội ngũ kỹ thuật viên tại công ty là những chuyên gia có kinh nghiệm, luôn đảm bảo rằng mỗi sản
            phẩm đều đáp ứng được tiêu chuẩn cao nhất về tính thẩm mỹ, chất lượng và độ bền. Tuân Minh cung cấp một loạt
            các dịch vụ nhôm đúc, bao gồm cả thiết kế, gia công và lắp đặt, có khả năng sản xuất theo yêu cầu của khách
            hàng, đảm bảo đáp ứng được các yêu cầu đặc biệt và đưa ra các giải pháp tối ưu cho mọi dự án. Chất lượng là
            ưu tiên hàng đầu, cam kết sử dụng nguyên liệu chất lượng cao và tuân thủ các tiêu chuẩn và quy định ngành
            công nghiệp. Điều này đảm bảo rằng sản phẩm nhôm đúc của Tuân Minh có độ bền cao, khả năng chịu lực tốt và
            tuổi thọ dài. Khách hàng là trung tâm của mọi hoạt động, Tuân Minh tạo ra mối quan hệ đáng tin cậy với khách
            hàng, lắng nghe và hiểu rõ nhu cầu của họ để đảm bảo mỗi dự án được thực hiện thành công và vượt qua sự mong
            đợi. Với cam kết về chất lượng, kỹ thuật tiên tiến và sự tận tâm đến khách hàng, nhôm đúc Tuân Minh tự tin
            rằng công ty là đối tác lý tưởng cho mọi nhu cầu nhôm đúc của khách hàng.
          </p>
          <p className='introduce_seo'>
            Công ty Nhôm đúc Tuân Minh chúng tôi có địa chỉ tại Buôn Ma Thuột, Đắk Lắk nhận thi công Toàn Quốc. Với dày
            dặn kinh nghiệm, đội ngũ lành nghề và ưu thế về nguồn nguyên vật liệu cả về giá và chất lượng. Chúng tôi cam
            kết sẽ mang đến khách hàng những sản phẩm CHẤT & RẺ nhất thị trường hiện nay.
          </p>
          <div className='introduce_experience'>
            <div className='introduce_experience_1'>
              <h3>10+</h3>
              <p>Năm kinh nghiệm</p>
            </div>
            <div className='introduce_experience_2'>
              <h3>6000+</h3>
              <p>Công trình hoàn thiện</p>
            </div>
          </div>
        </div>
        <div className='introduce_img'>
          <img src={process.env.PUBLIC_URL + '/images/introduce.png'} alt='thi-cong-nhom-duc-tot-nhat-bmt-dak-lak' />
        </div>
      </div>
    </>
  )
}

export default Introduce
