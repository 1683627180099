import React from 'react'
import HeaderDestop from './components/headerDestop'
import { UseViewport } from '../../util/screen'
import HeaderMobile from './components/headerMobile/HeaderMobile'

const Header = () => {
  // const viewPort = UseViewport()
  // const isMobile = viewPort.width <= 760

  return (
    <div>
      {UseViewport(760) && <HeaderMobile />}
      {!UseViewport(760) && <HeaderDestop />}
    </div>
  )
}

export default Header
