import React, { useEffect } from 'react'
import './style.scss'
import { useNavigate } from 'react-router-dom'

const Notfound = () => {
  // const navigate = useNavigate()
  // useEffect(() => {
  //   setTimeout(() => {
  //     navigate(-1)
  //   }, 3000)
  // }, [])

  return (
    <div className='notfound_box'>
      <p>Lỗi không tìm thấy trang. Đường dẫn không đúng</p>
      <img src={process.env.PUBLIC_URL + '/images/404.jpg'} />
    </div>
  )
}

export default Notfound
