import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal } from 'antd'
import './style.scss'
import { UseViewport } from '../../../../util/screen'
import { ScrollToElement } from '../../../../util/scrollToElement'
import { Context } from '../../../../util/context'
import DataPhone from '../../../../data/phone'

const ModalProduct = ({ isModal, setIsModal, valueModal }) => {
  const isMobile = UseViewport(760)

  const handleOk = () => {
    setIsModal(false)
  }
  const handleCancel = () => {
    setIsModal(false)
  }

  //lấy giá trị từ useContext
  const ValueContext = useContext(Context)
  const scrollDownFormr = ScrollToElement('scrollspy_form')
  const scrollDownFormClose = () => {
    ValueContext.setElement(valueModal.code)
    setIsModal(false)
    scrollDownFormr()
  }

  return (
    <div className='modal_product_box'>
      <Modal
        title={` ${valueModal.code}`}
        open={isModal}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={true} //click bên ngoài thì close modal
        footer={null}
        style={isMobile ? { width: '100%' } : { width: '50%', position: 'relative', top: '1.2rem' }}
      >
        <div>
          <img src={valueModal.img} style={{ width: '100%' }} />
        </div>
        <div className='footer_modal'>
          <div>
            {isMobile ? (
              <a href={`tel:${DataPhone.phone}`}>
                <button className='button_dat'>
                  <i className='fas fa-phone-volume'></i> Gọi ngay
                </button>
              </a>
            ) : (
              <b>Liên hệ: {DataPhone.phone_dot}</b>
            )}
          </div>
          <button className='button_dat' onClick={scrollDownFormClose}>
            Đặt ngay
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default ModalProduct
