import React, { useContext, useEffect, useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import './style.scss'
import { Context } from '../../../../util/context'

const Formmail = () => {
  const form = useRef()
  const initialNoti = {
    message: '',
    isNoti: false
  }
  const [noti, setNoti] = useState(initialNoti)

  const initialValueForm = {
    name: '',
    phone: '',
    message: ''
  }
  const [activeButton, setActiveButton] = useState(true)

  const [valueForm, setValueForm] = useState(initialValueForm)

  //lấy giá trị từ useContext
  const ValueContext = useContext(Context)
  useEffect(() => {
    // document.getElementById('input_name').focus()
    ValueContext.element &&
      setValueForm((prev) => {
        return { ...prev, message: `Tôi quan tâm đến: "${ValueContext.element}"` }
      })
  }, [ValueContext.element])

  const handleChangrInput = (e) => {
    setNoti(initialNoti)
    setValueForm((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }

  const sendEmail = (e) => {
    e.preventDefault()
    setActiveButton(false)
    setNoti(initialNoti)

    const isPhone = /0[0-9]{9}$/.test(valueForm.phone.trim()) //bắt đầu bằng số 0, rồi tiếp tục là một trong các số từ 0 đến 9 trong 9 lần, rồi ngắt chuỗi đó đi (vì ko ngắt chuỗi thì sinh lỗi nó dư ra vẫn dc, dùng dấu $ cuối chuỗi, còn dấu ^ thì lấy đầu chuỗi)

    // const isPhone2 = /^-?[0-9]+$/.test('-978')  //Lấy các số dương hoặc âm

    if (isPhone) {
      emailjs.sendForm('service_dlde16s', 'template_xt75i4m', form.current, 'SL9SomTPt455iUmBL').then(
        (result) => {
          setNoti({ message: 'Gửi thông tin thành công. Chúng tôi sẽ liên hệ với bạn.', isNoti: true })
          setActiveButton(true)
          setValueForm(initialValueForm)
        },
        (error) => {
          console.log(error.text)
        }
      )
    } else {
      setNoti((prev) => {
        return { ...prev, message: 'Bạn chưa nhập số điện thoại, hoặc số không đúng ?' }
      })
      setActiveButton(true)
    }
  }

  return (
    <>
      <div id='scrollspy_form' style={{ position: 'relative', top: '-4.8rem' }}></div>
      <div className='form_mail_div'>
        <h1>Nhập thông tin</h1>
        <p>( Để lại thông tin chúng tôi sẽ liên hệ lại với bạn )</p>
        <div className='form_mail_box'>
          <form ref={form} onSubmit={sendEmail} className='form_mail'>
            <div>
              <div className='row'>
                <div className='col_left'>
                  <label htmlFor='input_name'>Tên của bạn:</label>
                </div>
                <div className='col_right'>
                  <input
                    type='text'
                    name='name'
                    className='input'
                    id='input_name'
                    value={valueForm.name}
                    onChange={handleChangrInput}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col_left'>
                  <label htmlFor='input_phone'>Số điện thoại:</label>
                </div>
                <div className='col_right'>
                  <input
                    type='text'
                    name='phone'
                    className='input'
                    id='input_phone'
                    value={valueForm.phone}
                    onChange={handleChangrInput}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col_left'>
                  <label htmlFor='input_message'>Lời nhắn:</label>
                </div>
                <div className='col_right'>
                  <textarea
                    name='message'
                    className='input textarea'
                    id='input_message'
                    value={valueForm.message}
                    onChange={handleChangrInput}
                  />
                </div>
              </div>

              {activeButton && (
                <div className='row'>
                  <div className='col_left'></div>
                  <div className='col_right'>
                    <input type='submit' value='Gửi' className='buttom_form_mail' />
                    {noti.message && <p className={noti.isNoti ? 'noti' : 'noti_error'}>{noti.message}</p>}
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Formmail
