import React, { useEffect, useState } from 'react'
import './style.scss'
import Nav from '../nav'
import Slide from '../slide'

const HeaderDestop = () => {
  const [moveContact, setMoveContact] = useState(false)
  useEffect(() => {
    moveContact &&
      setTimeout(() => {
        setMoveContact(false)
      }, 8000)
  }, [moveContact])

  return (
    <div className='head_div_box'>
      <div className='img_head_box'>
        <img className='img_main' src={process.env.PUBLIC_URL + '/images/main.png'} alt='nhom-duc-bmt-dak-lak' />
        <img
          className='img-head'
          src={process.env.PUBLIC_URL + '/images/logo.gif'}
          alt='nhom-duc-dep-re-nhat-bmt-dak-lak'
        />
      </div>

      <Nav name_class_nav='nav_ul_destop' setMoveContact={setMoveContact} />
      <Slide moveContact={moveContact} />
    </div>
  )
}

export default HeaderDestop
