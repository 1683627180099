import React from 'react'
import './style.scss'
import { useParams } from 'react-router-dom'
import DataPolicy from '../../../../data/policy'

const Policy = () => {
  const { policy } = useParams()

  const policyFind = DataPolicy.find((item) => item.path == policy)

  return (
    <div className='policy_box'>
      <h1>{policyFind?.name}</h1>
      <p>{policyFind?.content}</p>
    </div>
  )
}

export default Policy
