import React, { useEffect, useState } from 'react'
import './style.scss'
import { Button, Select } from 'antd'
import DataVideo from '../../../../data/video'
import baiviet from '../../../../data/baiviet'
import { Link } from 'react-router-dom'

import { UseViewport } from '../../../../util/screen'
import { ScrollToElement } from '../../../../util/scrollToElement'

const Post = () => {
  const [video, setVideo] = useState(DataVideo[0].code)
  const [number, setNumber] = useState(0)
  const where = baiviet[number]
  const handleClickSetNumber = () => {
    setNumber((pre) => {
      if (pre < baiviet.length - 1) {
        return pre + 1
      } else return 0
    })
  }

  const handleChange = (code_video) => {
    setVideo(code_video)
  }

  const valueSelect = DataVideo.map((video) => {
    return { value: video.code, label: video.name }
  })

  const isMobile = UseViewport(760)

  const scrollDownCategory = ScrollToElement('scrollspy_category')
  return (
    <>
      <div id='scrollspy_post' style={{ marginBottom: '50px' }}></div>
      <div className='post_box'>
        <div className='news'>
          <h2>Tin tức</h2>
          <img src={where?.img} alt={where?.alt3} />
          <h5>Thi công Cổng cửa, hàng rào,.. nhôm đúc tại {where?.diachi1} </h5>
          <p>Nhôm đúc Tuân Minh tự hào là điểm đến lý tưởng cho khách hàng cam kết Chất & Rẻ nhất...</p>
          <div className='news_control'>
            <span className='news_control_button'>
              <Link to={where?.url}>
                <Button onClick={!isMobile ? scrollDownCategory : () => {}}>Xem thêm</Button>
              </Link>
            </span>
            <span className='news_control_span'>
              <span onClick={handleClickSetNumber}>
                Tin khác <i className='fas fa-arrow-right'></i>
              </span>
            </span>
          </div>
        </div>

        <div className='video'>
          <h2>Video</h2>
          <iframe
            src={`https://www.youtube.com/embed/${video}`}
            alt='nhom-duc-tai-bmt-dak-lak'
            allowFullScreen
            width={'100%'}
            height={'300rem'}
          />
          <Select
            defaultValue={DataVideo[0].name}
            style={{ width: '100% ' }}
            onChange={handleChange}
            options={valueSelect}
          />
        </div>
      </div>
    </>
  )
}

export default Post
