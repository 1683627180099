import React, { useEffect, useState } from 'react'
import './style.scss'
import { Link, useNavigate } from 'react-router-dom'
import { CaretDownFilled } from '@ant-design/icons'
import { UseViewport } from '../../../../util/screen'
import DataCategories from '../../../../data/categories'
import { ScrollToElement } from '../../../../util/scrollToElement'

const Nav = ({ name_class_nav, setIsModal, setMoveContact }) => {
  const closeNav = () => setIsModal(false)
  const isMobile = UseViewport(760)

  const scrollDownCategory = ScrollToElement('scrollspy_category')
  const scrollDownIntroduce = ScrollToElement('scrollspy_introduce')
  const scrollDownPost = ScrollToElement('scrollspy_post')
  const scrollDownFooter = ScrollToElement('scrollspy_footer')

  const scrollDownIntroduceClose = () => {
    closeNav()
    scrollDownIntroduce()
  }
  const scrollDownPostClose = () => {
    closeNav()
    scrollDownPost()
  }
  const scrollDownFooterClose = () => {
    closeNav()
    scrollDownFooter()
  }

  return (
    <div className='nav_box'>
      <ul className={name_class_nav}>
        <li>
          <Link to='/' onClick={isMobile && closeNav}>
            Trang chủ
          </Link>
        </li>
        <li className='nav_li_sanpham'>
          Sản phẩm
          <i className='fas fa-sort-down icon_down'></i>
          <ul className='nav_ul_li_sanpham'>
            {DataCategories.map((category) => {
              return (
                <li key={category.id}>
                  <Link to={category.url} onClick={isMobile ? closeNav : scrollDownCategory}>
                    {category.name}
                  </Link>
                </li>
              )
            })}
          </ul>
        </li>
        <li className='nav_li_banggia'>
          Bảng giá <i className='fas fa-sort-down icon_down'></i>
          <ul className='nav_ul_li_banggia'>
            {DataCategories.map((category) => {
              return (
                <li key={category.id}>
                  <Link to={category.url_price} onClick={isMobile && closeNav}>
                    {category.name}
                  </Link>
                </li>
              )
            })}
          </ul>
        </li>
        <li onClick={isMobile ? scrollDownPostClose : scrollDownPost}>Tin tức</li>
        <li onClick={isMobile ? scrollDownIntroduceClose : scrollDownIntroduce}>Giới thiệu</li>
        <li onClick={isMobile ? scrollDownFooterClose : () => setMoveContact(true)}>Liên hệ</li>
        {/* <li>
          {name_class_nav == 'nav_ul_mobile' && (
            <img className='img_main_mobile' src={process.env.PUBLIC_URL + '/images/main.png'} />
          )}
        </li> */}
      </ul>
    </div>
  )
}

export default Nav
