import React, { useState } from 'react'
import './style.scss'
import { MenuUnfoldOutlined, CloseOutlined } from '@ant-design/icons'
import Nav from '../nav'
import Slide from '../slide'
import Contact from '../contact'
import { Link } from 'react-router-dom'

const HeaderMobile = () => {
  const [isModal, setIsModal] = useState(false)
  const handleIsModal = () => {
    setIsModal(!isModal)
  }

  return (
    <div>
      <div className='head_mobile'>
        {/* <MenuUnfoldOutlined className='icon_menu' onClick={handleIsModal} /> */}
        <i className='fas fa-bars icon_menu' onClick={handleIsModal}></i>
        <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt='nhom-duc-dep-re-nhat-bmt-dak-lak' />
        {/* {name_class_nav == 'nav_ul_mobile' && <Contact />} */}
        <Contact />

        <Link to='/' className='ve_home'></Link>
      </div>
      <div className={isModal ? 'modal' : 'hidden'}>
        <div className='modal_overlay' onClick={handleIsModal}></div>
        <div className='modal_body'>
          {/* <div className='nav_box'>
            <img className='img_main' src={process.env.PUBLIC_URL + '/images/main.png'} />
          </div> */}
          <Nav name_class_nav='nav_ul_mobile' setIsModal={setIsModal} />
        </div>
        <CloseOutlined className='icon_close' onClick={() => setIsModal(false)} />
      </div>

      {/* thêm thẻ div này để thế vào vị trí của header trên mobile khi fixed  */}
      <div style={{ height: 54, position: 'relative', top: 0 }}></div>
      <Slide />
    </div>
  )
}

export default HeaderMobile
