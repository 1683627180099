import React, { useCallback, useState } from 'react'
import './style.scss'
import DataCategories from '../../../../data/categories'
import DataProducts from '../../../../data/products'
import Slider from 'react-slick'
import { UseViewport } from '../../../../util/screen'
import { DoubleRightOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import ModalProduct from '../modalProduct'
import { ScrollToElement } from '../../../../util/scrollToElement'

const CategoryList = () => {
  const [isModal, setIsModal] = useState(false)
  const [valueModal, setValueModal] = useState({})
  const handleSetIsModal = (value) => {
    setIsModal(true)
  }

  const isMobile = UseViewport(760)
  const scrollDownCategory = ScrollToElement('scrollspy_category')

  function PrevArrow(props) {
    const { className, style, onClick } = props
    return <div className={className} style={{ ...style, display: 'none', color: 'green' }} onClick={onClick} />
  }
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    // slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true, //Rất hay để kéo tùy thích
    cssEase: 'ease-in-out',
    // fade: true,
    autoplay: true,
    autoplaySpeed: 6000,
    // pauseOnHover: true,
    // nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  }

  const Slider_filter = (cat_id) => {
    const productList = DataProducts.filter((item) => item.cat == cat_id)
    const category = DataCategories.find((item) => item.id == cat_id)

    const [dragging, setDragging] = useState(false) //Khi scroll thì ko click trên react slick
    const handleBeforeChange = useCallback(() => {
      setDragging(true)
    }, [setDragging])

    const handleAfterChange = useCallback(() => {
      //onClickCapture chỉ chụp khi click, nên cái này thành false vẫn ko chạy như onClick
      setDragging(false)
    }, [setDragging])

    const handleOnItemClick = useCallback(
      (e) => {
        if (dragging) {
          e.stopPropagation()
        } else handleSetIsModal()
      },
      [dragging]
    )

    return (
      <Slider
        {...settings}
        slidesToShow={isMobile ? 2 : 4}
        beforeChange={handleBeforeChange}
        afterChange={handleAfterChange}
      >
        {productList?.map((product) => {
          return (
            <div className='div_img' key={product.id}>
              <img
                className='img_slider'
                alt={`${category.name_path}-dak-lak`}
                src={product.img}
                key={product.id}
                onClickCapture={(e) => {
                  handleOnItemClick(e)
                  setValueModal({ img: product.img, code: product.code })
                }}
              />
              <span
                onClickCapture={(e) => {
                  handleOnItemClick(e)
                  setValueModal({ img: product.img, code: product.code })
                }}
              >
                {product.code}
              </span>
            </div>
          )
        })}
      </Slider>
    )
  }
  return (
    <div>
      <div className='categories_div'>
        {DataCategories.map((category) => (
          <div className='category' key={category.id}>
            <div className='category_name'>
              <p>{category.name}</p>
              <div>
                <Link
                  to={category.url}
                  onClick={!isMobile ? scrollDownCategory : () => {}}
                  style={{ textDecoration: 'none' }}
                >
                  <span>Xem thêm</span>
                  <span>
                    <DoubleRightOutlined />
                  </span>
                </Link>
              </div>
            </div>
            <div className='slider_box'>{Slider_filter(category.id)}</div>
          </div>
        ))}
      </div>
      <ModalProduct isModal={isModal} setIsModal={setIsModal} valueModal={valueModal} />
    </div>
  )
}

export default CategoryList
