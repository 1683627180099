import React, { useEffect, useState } from 'react'
import './style.scss'
import { Col, Row } from 'antd'
import { useLocation, useParams } from 'react-router-dom'
import DataProducts from '../../../../data/products'
import DataCategories from '../../../../data/categories'
import ModalProduct from '../modalProduct'
import { ScrollToElement } from '../../../../util/scrollToElement'
import { Helmet } from 'react-helmet-async'
import { UseViewport } from '../../../../util/screen'
import DataPhone from '../../../../data/phone'

const ProductList = () => {
  const { url_category } = useParams()
  const currentPath = useLocation().pathname

  const [isModal, setIsModal] = useState(false)
  const [valueModal, setValueModal] = useState({})
  const showModalProduct = (value) => {
    setIsModal(true)
    setValueModal(value)
  }

  const handleIdCategory = () => {
    if (url_category == 'cong-nhom-duc-bmt') return 1
    if (url_category == 'lan-can-nhom-duc-bmt') return 2
    if (url_category == 'cau-thang-nhom-duc-bmt') return 3
    if (url_category == 'hang-rao-nhom-duc-bmt') return 4
  }

  const productList = DataProducts.filter((item) => item.cat == handleIdCategory())
  const category = DataCategories.find((item) => item.name_path == url_category)

  const scrollDownFooter = ScrollToElement('scrollspy_price')
  const isMobile = UseViewport(760)

  //Lần đầu scrollDown bảng giá cái này
  useEffect(() => {
    if (currentPath.includes('bang-gia')) {
      const elementPrice = document.getElementById('scrollspy_price')
      setTimeout(() => {
        elementPrice.scrollIntoView({
          behavior: 'smooth'
        })
      }, 1200)
    }

    // Hiệu ứng gõ chữ
    if (!isMobile) {
      let element = document.getElementById('typing-effect')
      let text = `Ngoài ra còn nhiều mẫu khác và theo yêu cầu thiết kế từ gia chủ`
      const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
      const handleText = async () => {
        for (let i = 0; i < text.length; i++) {
          element.textContent += text[i]
          await sleep(88)
        }
      }

      handleText()
      setInterval(() => {
        element.textContent = ''
        handleText()
      }, 8888)
    }
  }, [])

  //Các Lần sau scrollDown bảng giá cái này
  useEffect(() => {
    if (currentPath.includes('bang-gia')) {
      scrollDownFooter()
    }
  }, [currentPath])

  return (
    <>
      {currentPath.includes('bang-gia') && (
        <Helmet>
          <title>{`Báo giá ${category?.name} tại BMT, Đắk Lắk mới nhất ${new Date().getFullYear()}`}</title>
          <meta
            name='description'
            content={`Giá thi công ${category?.name} tại Buôn Ma Thuột, Đắk Lắk. Nhôm đúc Rẻ Nhất thị trường. Nhận thi công Toàn Quốc. Hơn 1000+ khách hàng`}
          />
        </Helmet>
      )}

      {!currentPath.includes('bang-gia') && (
        <Helmet>
          <title>{`Lắp đặt ${category?.name} tại BMT, Đắk Lắk - Chất & Rẻ 100%`}</title>
          <meta
            name='description'
            content={`Thiết kế Thi công ${category?.name} tại Buôn Ma Thuột, Đắk Lắk cam kết Chất & Rẻ Nhất. Nhận thi công Toàn Quốc. Hơn 1000+ khách hàng`}
          />
        </Helmet>
      )}
      <div className='product_list_box'>
        <h5>{category?.name}</h5>
        <Row>
          {productList.map((product) => {
            return (
              <Col xs={12} sm={12} md={8} lg={6} xl={6} key={product.id} className='product_list_col'>
                <img
                  src={product.img}
                  onClick={() => showModalProduct({ img: product.img, code: product.code })}
                  alt={`${category.name_path}-dak-lak`}
                />
                <p onClick={() => showModalProduct({ img: product.img, code: product.code })}>{product.code}</p>
              </Col>
            )
          })}
        </Row>
        <div className='product_add'>
          {!isMobile && <p className='product_add_text' id='typing-effect'></p>}
          {isMobile && (
            <marquee
              width='100%'
              scrolldelay='240'
              // scrollamount='6'
              behavior='scroll'
              bgcolor='pink'
              className='product_add_text'
            >
              Ngoài ra còn nhiều mẫu khác và theo yêu cầu thiết kế từ khách hàng.
            </marquee>
          )}
        </div>
        <div className='description'>
          <h1>{`${category.name}`}</h1>
          {category.description.map((item, index) => (
            <div key={index}>
              <p>{item}</p> <br />
            </div>
          ))}
        </div>

        <div id='scrollspy_price' style={{ position: 'relative', top: '-4.8rem' }}></div>
        <div className='price'>
          <h1>{`Báo giá ${category.name_lowercase} mới nhất ${new Date().getFullYear()} `}</h1>
          <p>{`Giá ${category.name_lowercase} dao động từ: ${category.price} `}</p>
          <p>{`Giá ${category.name_lowercase} thường phụ thuộc vào các yếu tố sau: `}</p>
          {category.dependent.map((item, index) => (
            <ul key={index} className='dependent'>
              <li>{item}</li>
            </ul>
          ))}
          <i>{`( Vì vậy để có giá ${category.name_lowercase} cần phụ thuộc nhiều yếu tố. Quý khách hàng vui lòng gọi ${DataPhone.phone_dot} để được báo giá chi tiết ạ! )`}</i>
        </div>
        <ModalProduct isModal={isModal} setIsModal={setIsModal} valueModal={valueModal} />
      </div>
    </>
  )
}

export default ProductList
